import React from 'react'
import styled from 'styled-components'
import Wrapper from './Wrapper'
import { colors } from '../tokens'
import useSiteMetadata from "../hooks/use-site-config";
import useSiteImages from "../hooks/use-site-images";

const Title = styled.h3`

`;
const Subtitle = styled.small`

`;

const SignupNow = (props) => {
  return (
    <a href={props.href}>
      <Title>

      </Title>
      <Subtitle>
        Ilmoittautuminen .
      </Subtitle>
      {props.late &&
      <small>
        Ilmoittautumisajan jälkeen, toiselle ja kolmannelle tunnille voit vielä tulla suoraan paikalle.
      </small>
      }
    </a>
  );
}


const GetNotified = (props) => {
  // Button -> turns to form -> turns to "thank you" text
  // NOTE: Should we take only emails, or also do SMS notifications as well? And where do we store the data?
  return (
    <a>

    </a>
  );
}



const BannerWrapper = styled(Wrapper)`
  padding: 0;
  border-radius: 5px 5rem 5rem 5px;
  box-shadow: 0 0 0 0, 0 6px 12px rgba(0,0,0,0.1);
  
  @media (max-width: 780px) {
    padding: 0;
    margin-right: 5vw;
    width: 95vw;
  }
  
  background: url(${props => props.bgimg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left bottom;
  
  a {
    @media (max-width: 780px) {
      padding: 1.5rem 1rem;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    
    
    /* background-color: #FDDB27; */
    /* background-color: #ebff49; */
    border-radius: 5px 5rem 5rem 5px;
    background-color: rgba(250, 255, 100, 0.75);
    
    /*background-color: rgba(160,255,100,0.75);  /* This kinda works!! */
    
    text-shadow: 1px 1px 2px rgba(255,255,255,0.6);
    
    /*background-color: #fdff5f;*/
    color: #000;
    padding: 1.5rem 3rem;
    h3 {
      font-size: 1.7rem;
      flex-grow: 1;
      @media (max-width: 780px) {
        font-size: 1.2rem;
      }
    }
    small {
      font-size: 1rem;
      display: block;
      padding-top: 0.6rem;
      color: #ef862d;
      
      @media (max-width: 780px) {
        font-size: 0.8rem;
      }
    }
    aside {
      @media (max-width: 780px) {
        display: none;
      }
    
      flex-grow: 0;
      flex-shrink: 0;
      text-align: right;
      font-size: 1.3rem;
      &.big {
        font-size: 1.7rem;
      }
    }
  }
`

const TempBanner = (props) => {
  return null;
  const bgimg = useSiteImages('bannerbg01.jpg').fluid.src;

  const url = "/marraskuun-alkeiskurssi-27-11-2022";
  return (
    <BannerWrapper bgimg={bgimg}>
      <a href={url}>
        <h3>
          Peruskurssiin ilmoittautuminen nyt auki!
          <small>Seuraava peruskurssi alkaa sunnuntaina 27.11.</small>
        </h3>
        <aside>
          Ilmoittaudu
        </aside>
        <aside class="big">&nbsp;&gt;</aside>
      </a>
    </BannerWrapper>);
}

export const SignupBanner = (props) => {
  return (<TempBanner />);
  /*
  const {basicsCourses} = useSiteMetadata();
  const {applyStartDate, applyEndDate, whenFi, whenEn} = basicsCourses.next;

  if(!applyStartDate || !applyEndDate) { return null; }

  const start = new Date(basicsCourses.next.applyStartDate);
  const end = new Date(basicsCourses.next.applyEndDate);
  const now = new Date();


  // If course signup open -> show "Sign-up now open!" -link
  if(now >= start && now <= end) {
    return (<SignupNow late={now > end} />);
  }

  // If not, show placeholder text (guesstimate) + element that turns into an email subscription thingy
  else if ()
*/
};

export default SignupBanner;