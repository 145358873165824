import React from 'react'
import styled from 'styled-components'
import Wrapper from './Wrapper'
import {Link} from 'gatsby'
import {ContentBody} from './Content'
import { colors } from '../tokens'

const BannerWrapper = styled(Wrapper)`
  padding: 20px 30px;

  line-height: 1.6;
  font-size: 0.8rem;

  h3 {
    font-size: 1.2rem;
    flex-grow: 1;
    @media (max-width: 780px) {
      font-size: 1.2rem;
    }
  }
  small {
    font-size: 0.6rem;
    display: block;
    padding-top: 0.6rem;
    //color: #ef862d;
    color: #666;
    
    @media (max-width: 780px) {
      font-size: 0.8rem;
    }
  }
  
  & a {
    box-shadow: 0 2px 0 0 ${colors.links};

    &:hover {
      filter: brightness(150%);
      box-shadow: none;
    }

    &.anchor,
    &.gatsby-resp-image-link {
      box-shadow: none;
    }
  }
`;

const Date = styled.td`
  font-weight: bold;
  padding-right: 0.8rem;
  vertical-align: top;
`;

const Text = styled.td`
  
`

const TextLatest = styled(Text)`
  font-weight: bold;
`

export const CovidBanner = (props) => {
  return (
    <BannerWrapper>
      <h3>Ajankohtaista</h3>
        <table>
          <tr><td>
            <Date>13.11.2022:</Date>
            <TextLatest>
              King of the Ringin alkeiskurssi alkaa 27.11. Infoa ja linkki ilmoittautumislomakkeelle <a href="/marraskuun-alkeiskurssi-27-11-2022">löytyy täältä</a>.
            </TextLatest>
          </td></tr>
        </table>
    </BannerWrapper>
  );
}

export default CovidBanner;
