import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Wrapper from '../components/Wrapper'
import Hero from '../components/Hero'
import PostsList from '../components/PostsList'
import Pagination from '../components/Pagination'
import SignupBanner from '../components/SignupBanner'
import CovidBanner from '../components/CovidBanner'
import SEO from '../components/SEO'
import Sidebar from "../components/Sidebar";
import useSiteImages from "../hooks/use-site-images";


const InfoBox = (props) => {
  return (
    <Wrapper>
      <small>Tiedotus / Announcement:</small>
      <div style="display: flexbox; flex-direction: row;">
        <div style="flex: 1 1 50%;">
          <h2></h2>
          <h3></h3>
        </div>
        <div style="flex: 1 1 50%;">
          <h2></h2>
          <h3></h3>
        </div>
      </div>
    </Wrapper>
  );
}

const HeroTitleImage = (props) => {
  const logoSrc = useSiteImages('logo-1-transparent-tight.png').fluid.src;

  // FIXME: GET THIS TO WORK!!! Should be better for SEO...
  /*
  return (
    <h1 style={{
      width: "100%",
      height: "100%",

    }}><a href="/"><img src={logoSrc} alt="King of the Ring" /></a></h1>
  )
  */

  return (
    <h1 style={{
      backgroundImage: `url("${logoSrc}")`,
      backgroundPosition: "center",
      width: "100%",
      height: "100%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundColor: "rgba(0,0,0,0.65)",
    }}>{/* <a href="/">King of the Ring</a> */}</h1>
  )
}

class BlogList extends React.Component {
  render() {
    const { title, description } = this.props.data.site.siteMetadata
    const posts = this.props.data.posts.edges
    const { pageContext } = this.props

      //const logoSrc = useSiteImages('logo-1-tight.png').fluid.src;

    // const heroLayer2 = useSiteImages('logo-1-transparent-tight.png').fluid.src;

    return (
      <Layout location={this.props.location}>
        <SEO />
        {/* title={title} subTitle={description} */}
        <Hero>
          <HeroTitleImage />
        </Hero>

        {/* <CovidBanner /> */}
        <SignupBanner />

        <Wrapper>
          <Sidebar />
          <PostsList posts={posts} />
        </Wrapper>

        <Pagination
          nbPages={pageContext.nbPages}
          currentPage={pageContext.currentPage}
        />
      </Layout>
    )
  }
}

export default BlogList

export const pageQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    posts: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fileAbsolutePath: { regex: "//content/posts//" }
        frontmatter: { published: { ne: false }, unlisted: { ne: true } }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            language
            slug
          }
        }
      }
    }
  }
`
